.gauge {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $mantine-breakpoint-m) {
    flex-direction: column;
    margin-top: 0;
  }
}

.percentageBar {
  background: var(--quo-power);
  background: conic-gradient(from 0deg at 50% 50%,
      #77c845 -93.28deg,
      #c84545 128.8deg,
      #fdac41 201.98deg,
      #77c845 266.72deg,
      #c84545 488.8deg);
  max-width: 300px;
  width: 100%;
  position: relative;
  border-radius: 50%/100% 100% 0 0;
  overflow: hidden;
  height: 150px;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: 0;
    right: -1px;
    background-color: var(--quo-grey-25);

    transform: rotate(var(--percentage-deg));
    transform-origin: bottom center;
  }
}

.riskMask {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  top: 20px;
  background-color: var(--grid-column-bg);
  border-radius: 50%/100% 100% 0 0;
}


.riskContainer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  .riskValue {
    display: block;
    color: var(--title-color);
    font-weight: bold;
    font-size: 32px;
    line-height: 37.5px;
    text-align: center;
  }

  .priority {
    display: block;
    color: var(--active-color);
    font-size: 24px;
    line-height: 30px;
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
  }
}
