.moduleContainer {
  &:not(:first-child) {
    margin-top: 25px;
  }

  &.expand {
    height: 100%;
  }

  &.removeMargin {
    margin: 0;
  }
}

.module {
  background-color: var(--grid-column-bg);
  border-radius: var(--border-radius);
  padding: 15px;
  width: 100%;

  &:not(:first-child) {
    margin-top: 15px;
  }

  &.expand {
    height: 100%;
  }

  .module & {
    padding: 0;
  }

  &.removePadding {
    padding: 0;
  }

  &.removeBackground {
    background-color: transparent;
  }
}

.grid {
  margin-top: 15px;
}

.inner {
  padding: 10px 15px;

  &.noPadding {
    padding: 0;
  }
}

.col {
  &:empty {
    display: none;
  }
}

.submoduleContent {
  &.scroll {
    max-height: 300px;
    overflow-y: auto;

    @media print {
      max-height: 100%;
      overflow-y: unset;
    }
  }

  :global(li),
  :global(span),
  :global(p) {
    overflow-wrap: break-word;
    word-break: break-word;
  }

  @media (min-width: $mantine-breakpoint-s) {
    .submoduleFlex & {
      margin-top: 0;
    }
  }
}

.moduleTitle {
  @media print {
    break-after: avoid-page;
  }
}

.submoduleTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  @media print {
    break-after: avoid-page;
  }

  :global(svg) {
    width: 20px;
    height: 20px;

    @media print {
      display: none;
    }
  }
}
