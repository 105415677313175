.wrapper {
  height: 100vh;
  width: 100%;

  @media (min-width: $mantine-breakpoint-s) {
    padding-left: 15px;
    padding-right: 15px;
  }

  form button[type="submit"] {
    width: 100%;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background: var(--quo-power);
    }
  }
}

.content {
  width: 100%;
  padding: 30px;
  background: var(--grid-column-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--grid-column-shadow) 0px 0 3px 0px;
  height: 100%;

  @media (min-width: $mantine-breakpoint-s) {
    max-width: 450px;
    height: auto;
  }

  h1 {
    text-align: center;
    margin: 0;
  }

  form {
    margin-top: 25px;
  }
}

.loginFade {
  &.enterActive form > *:not(:last-child),
  &.exitActive form > *:not(:last-child) {
    transition: opacity 200ms ease-in-out
  }

  &.enter form > *:not(:last-child)  {
    opacity: 0;
  }

  &.enterActive form > *:not(:last-child)  {
    opacity: 1;
  }

  &.exit form > *:not(:last-child)  {
    opacity: 1;
  }

  &.exitActive form > *:not(:last-child)  {
    opacity: 0;
  }
}
