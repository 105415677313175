.resultWrapper {
  padding: 15px;
  background: var(--grid-column-bg);
  border-radius: var(--border-radius);

  &:not(:first-child) {
    margin-top: 25px;
  }

  p {
    word-wrap: break-word;
    word-break: break-word;
  }
}

.description,
.footer {
  margin-top: 15px;
}
