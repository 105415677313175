.flag {
  fill: var(--quo-power);
}

.contextPrint {
  display: none;

  @media print {
    display: block;
  }
}
