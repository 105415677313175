
.fullPageWrapper {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}

.fullPageContent {
  background-color: var(--grid-column-bg);
  border-radius: var(--border-radius);
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $mantine-breakpoint-m) {
    width: 750px;
  }

  h1 {
    color: var(--quo-power-75);
    text-align: center;
  }
}
