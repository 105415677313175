.wrapper {
  padding: 15px;
}

.title {
  font-size: 24px;
}

.menuItem {
  border-bottom: 1px solid var(--dropdown-item-border);

  &:hover {
    background-color: var(--dropdown-item-hover-bg);
  }
}

.menuItemtext {
  font-weight: bold;
  margin-right: 10px;
  color: var(--notification-unread-color);
  width: 45px;
  margin-left: auto;
  text-align: center;
}

.badge {
  position: absolute;
  top: -3px;
  right: -5px;
  min-width: 20px;
  padding: 1px;
  font-size: 7px;
  font-weight: bold;
  color: var(--quo-white);
  text-align: center;
  background-color: var(--notification-badge-color);
  border-radius: 10px;
}

.badgeContainer {
  position: relative;
  display: inline-block;
}
