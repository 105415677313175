.reliability {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  --reliability-1: #4cbb17;
  --reliability-2: #0b6623;
  --reliability-3: #dba521;
  --reliability-4: #ff0801;
  --reliability-5: #960019;
  --reliability-6: #616161;

  &.score--1,
  &.score--A {
    background: var(--reliability-1);
  }
  &.score--2,
  &.score--B {
    background: var(--reliability-2);
  }
  &.score--3,
  &.score--C {
    background: var(--reliability-3);
  }
  &.score--4,
  &.score--D {
    background: var(--reliability-4);
  }
  &.score--5,
  &.score--E {
    background: var(--reliability-5);
  }
  &.score--6,
  &.score--F {
    background: var(--reliability-6);
  }

  &.compact {
    font-size: 12px;
    width: 25px;
    height: 25px;
  }
}
