.wrapper {
  display: inline-flex;
  align-items: center;
}

@keyframes breathe {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.icon {
  margin-right: 10px;
  background: var(--quo-power-75);
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.animated {
    opacity: 0;
    animation: breathe 2s linear infinite;
  }
}
