.triangle {
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid black;
}

/* eslint-disable */
.wrapper {
  --border-color: var(--quo-pink);

  span {
    border-bottom-color: var(--border-color);
  }

  &:global(.priority-1) {
    --border-color: #ffc917;
  }

  &:global(.priority-2) {
    --border-color: var(--quo-yellow);
  }

  &:global(.priority-3) {
    --border-color: var(--quo-power);
  }
}
