.header {
  justify-content: flex-end;
}

.image {
  max-height: calc(100vh - 165px);
  max-width: 100%;
  object-fit: contain;
}

.tableContainer {
  height: 100px;

  :global(thead) {
    background-color: var(--grid-column-bg);
  }
}

.tablePrint {
  display: none;

  @media print {
    display: block;
  }
}

.clampedContainer {
  @supports (-webkit-hyphens: none) {
    * {
      display: inline;

      &::after {
        content: ' \A\A';
        white-space: pre;
      }
    }
  }
}
